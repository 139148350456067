@media (max-width: 768px) {
  .marketContainer {
    width: 100%
  }
}

@media (min-width: 1600px) {
  .textNoWrap {
    white-space: nowrap;
  }
}

@media (max-width: 1600px) {
  .textNormalWrap {
    white-space: normal;
  }
}
