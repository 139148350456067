@media (min-width: 1650px) {
    .headingContainer {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0.5rem;
    }

    .fullHeight {
        height: 100%;
    }
}